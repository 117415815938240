@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 5px #fffcb6;
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 10px #ffd700;
    transform: scale(1.05);
  }
  100% {
    box-shadow: 0 0 5px #fffcb6;
    transform: scale(1);
  }
}
.shadow-pulse {
  animation: shadow-pulse 1.5s infinite;
}

.shadow-pulse:hover {
  animation: none;
}

.App {
  text-align: center;
}
